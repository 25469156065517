_.delay(function() {
  $.ajax({
          url: TimeLedger.apiBaseUrl + '/login',
          type: 'POST',
          data: $.param({ "grant_type": "refresh_token", "refresh_token": JSON.parse(sessionStorage.authResponse).refresh_token }),
          contentType: 'application/x-www-form-urlencoded',
          success: function() { log.error('success.'); },
          error: function() { log.error('failure.'); },
          context: this
      });
}, 60000);
